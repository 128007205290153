h1 {
	font-family: $header;
	font-weight: 400;
	font-size: 2rem;
	margin: 0 0 1rem;
	line-height: 1.2;

	@include bp(sm) {
		font-size: 1.8rem;
	}
}

h2 {
	font-family: $header;
	font-weight: 600;
	font-size: 1.4rem;
	margin: 0;

	@include bp(sm) {
		font-size: 1.2rem;
	}
}

h3 {
	font-family: $header;
	font-weight: 600;
	font-size: 1.2rem;
	margin: 0 0 1rem;
	color: var(--primary);

	@include bp(sm) {
		font-size: 1rem;
	}
}

h1, h2, h4, h5 {
	color: var(--on-background);
}

p, ul, ol {
	margin: 0 0 1rem;
	color: var(--on-background);

	&:last-child {
		margin-bottom: 0;
	}
}

ol {
	padding-left: 20px;
}

ul {
	list-style: none;
	padding: 0;

	&.credits {
		font-size: 0.8rem;
		list-style: none;
	}
}

a {
	text-decoration: none;
	color: var(--primary);

	&:hover {
		color: var(--primary-variant);
	}
}

.no-wrap {
	white-space: nowrap;

	@include bp(mini) {
		white-space: normal;
	}
}