$header: proxima-nova, sans-serif;
$body: proxima-nova, sans-serif;
$verse: grad, serif;

@media (prefers-color-scheme: light) {
	:root {
		--primary: #F2664C;
		--primary-variant: #E5593F;
		--secondary: #FEBE2A;
		--background: #FFF;
		--background-opacity: 255,255,255;
		--surface: #F2F2F2;
		--accent: #C3C3C3;
		--accent-variant: #909090;
		--error: #BB1D30;
		--error-variant: #A20417;
		--on-background: #3B3B3B;
		--on-surface: #555;
		--on-surface-variant: #3B3B3B;
		--on-primary: #FFF;
		--on-error: #FFF;
	}
}

@media (prefers-color-scheme: dark) {
	:root {
		--primary: #FF8066;
		--primary-variant: #FF8D73;
		--secondary: #FEBE2A;
		--background: #1C1C1D;
		--background-opacity: 28,28,29;
		--surface: #3B3B3B;
		--accent: #555;
		--accent-variant: #7B7B7B;
		--error: #E14356;
		--error-variant: #EE5063;
		--on-background: #C3C3C3;
		--on-surface: #C3C3C3;
		--on-surface-variant: #D0D0D0;
		--on-primary: #1C1C1D;
		--on-error: #FFF;
	}
}