.create-form {
	&.disabled {
		opacity: 0.5;
		pointer-events: none;
		cursor: default;
	}

	.flex-form {
		display: flex;
		justify-content: space-between;
		margin-bottom: 1rem;
		@include bp(mini) {
			display: block;
		}

		> div {
			&.fields {
				flex-basis: 75%;
				margin-right: 1rem;
				@include bp(mini) {
					margin-right: 0;
				}

				:last-child {
					input {
						margin-bottom: 0;
					}
				}

				.color-field {
					input {
						text-transform: uppercase;
						@include bp(mini) {
							margin-bottom: 1rem;
						}
					}

					::-webkit-input-placeholder {
						text-transform: initial;
					 }
					 
					 :-moz-placeholder { 
						text-transform: initial;
					 }
					 
					 ::-moz-placeholder {  
						text-transform: initial;
					 }
					 
					 :-ms-input-placeholder { 
						text-transform: initial;
					 }

					.color-span {
						position: absolute;
						width: 20px;
						height: 20px;
						border-radius: 100%;
						right: 10px;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}

			&.picker {
				max-width: 50% !important;
				@include bp(mini) {
					max-width: none !important;
				}

				.chrome-picker {
					height: 100%;
					max-width: 100%;
					background-color: var(--surface) !important;
					@include bp(mini) {
						margin-bottom: 1rem;
						max-width: none;
						width: 100% !important;
					}

					input {
						all: revert;
						color: var(--on-surface) !important;
						background-color: var(--surface) !important;
						outline: none;
					}
				}
			}
		}
	}

	div.rte {
		border: 1px solid var(--accent);
		margin-bottom: 1rem;
		background-color: var(--surface);
		color: var(--on-surface);

		&:focus-within {
			border-color: var(--primary);
		}

		.rte-editor {
			height: 300px;
			overflow-y: scroll;
		}

		input {
			all: revert;
		}

		button {
			background: var(--surface);

			span {
				@media (prefers-color-scheme: dark) {
					filter: invert(80%);
				}
			}
		}
	}
}

.upload-form {
	border-bottom: 2px solid var(--accent);
	padding-bottom: 1rem;
	margin-bottom: 1rem;

	.delete-image {
		text-align: right;
	}

	button {
		margin-bottom: 0;
		@include bp(sm) {
			width: 100%;
		}
	}
}

.flex-buttons {
	display: flex;
	justify-content: space-between;
	@include bp(sm) {
		display: block;
	}

	&.create {
		justify-content: flex-start;
	}

	button {
		margin-right: 10px;
		@include bp(sm) {
			margin: 0 0 10px 0;
			width: 100%;
		}
		
		&.danger {
			margin-right: 0;
		}
	}
}

.field {
	position: relative;

	label {
		position: absolute;
		top: 7px;
		left: 11px;
	}
}

input {
	accent-color: var(--primary);
}

input:not([type=radio]), textarea {
	width: 100%;
	display: block;
	margin-bottom: 1rem;
	border: 1px solid var(--accent);
	padding: 26px 10px 8px;
	border-radius: 3px;
	font-size: 0.9rem;
	font-family: $header;
	outline: none;
	background-color: var(--surface);
	color: var(--on-surface);

	&:focus {
		border-color: var(--primary);

		+ label {
			color: var(--primary);
		}
	}

	&:disabled {
		background-color: var(--accent);
		color: var(--accent-variant);
		border-color: var(--accent);

		+ label {
			color: var(--accent-variant);
		}
	}

	&::placeholder {
		color: var(--accent);
	}
}

input[type=submit], button, a.cta {
	background-color: var(--primary);
	color: var(--on-primary);
	padding: 8px 16px;
	text-align: center;
	border: none;
	font-size: 0.9rem;
	font-family: $header;
	font-weight: 700;
	border-radius: 100px;
	text-decoration: none;
	margin-bottom: 1rem;
	position: relative;
	overflow: hidden;
	cursor: pointer;

	&:after {
		content: '';
		display: none;
		position: absolute;
		border-radius: 100%;
		background-color: rgba(255,255,255,0.15);
		width: 100px;
		height: 100px;
		margin-top: -50px;
		margin-left: -50px;
		top: 50%;
		left: 50%;
		animation: ripple 1s;
		opacity: 0;
	}

	&:focus:not(:active)::after {
		display: block;
	}

	&:active, &:focus {
		border: none;
		outline: none;
	}

	&:hover {
		background-color: var(--primary-variant);
	}

	&:disabled {
		background-color: var(--accent);
		cursor: default;
	}

	&.danger {
		background-color: var(--error);
		color: var(--on-error);

		&:hover {
			background-color: var(--error-variant);
		}
	}
}

label {
	color: var(--accent-variant);
	font-size: 0.75rem;
}

@keyframes ripple {
	from {
		opacity: 1;
		transform: scale(0);
	}

	to {
		opacity: 0;
		transform: scale(10);
	}
}