.admin-list {
	li {
		margin-bottom: 10px;
		font-family: $header;
		font-size: 0.9rem;

		.row {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 10px;
			border-radius: 3px;
			background-color: var(--surface);

			.data {
				display: flex;
				justify-content: flex-start;
				align-items: flex-end;
				@include bp(sm) {
					display: block;
				}

				span {
					margin-right: 10px;
					@include bp(sm) {
						display: block;
						margin-right: 0;
					}

					&.admin {
						font-size: 0.8rem;
						font-weight: bold;
						color: var(--accent);
					}
				}
			}

			.buttons {
				display: flex;

				a {
					display: block;

					&:not(:last-child) {
						margin-right: 10px;
					}

					@include bp(sm) {
						display: block;
					}

					button {
						background: var(--on-surface);
						-webkit-mask-repeat: no-repeat;
						mask-repeat: no-repeat;
						-webkit-mask-position: 50% 70%;
						mask-position: 50% 70%;
						width: 1.1rem;
						height: 100%;
						padding: 0;
						margin-bottom: 0;
						border-radius: 0;

						&:hover {
							background: var(--primary);
						}

						&.view {
							-webkit-mask-image: url('/images/view.svg');
							mask-image: url('/images/view.svg');
						}

						&.edit {
							-webkit-mask-image: url('/images/edit.svg');
							mask-image: url('/images/edit.svg');
						}

						&.danger {
							background: var(--error);
							-webkit-mask-image: url('/images/trash.svg');
							mask-image: url('/images/trash.svg');
							-webkit-mask-size: 90%;
							mask-size: 90%;

							&:hover {
								background: var(--error-variant);
							}
						}
					}
				}
			}
		}
	}
}