.offline, #notification {
	font-family: $header;
	font-size: 0.8em;
	color: #fff;
	text-align: center;
	position: fixed;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	padding: 20px;
	width: 450px;
	max-width: calc(100% - 40px);
	background-color: rgba(53,54,58,0.9);
	border: 1px solid #202124;
	border-radius: 6px;
	z-index: 2;
	filter: drop-shadow(2px 4px 6px rgba(0,0,0,0.5));

	button {
		background: none;
		padding: 0;
		margin: 0;
		color: var(--secondary);
		font-weight: bold;
		text-transform: uppercase;
		display: inline-block;
		font-size: inherit;
		border-radius: 0;
	}
}

#notification {
	display: none;

	&.visible {
		display: block;
	}

	span {
		margin: 0 4px;
	}

	button {
		&:first-of-type {
			margin-right: 8px;
		}
	}
}