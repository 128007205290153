nav {
	* {
		&:active {
			transition: ease opacity 0.2s;
			opacity: 0.8;
		}
	}

	font-family: $header;
	font-weight: 700;
	font-size: 0.9rem;
	padding: 20px;
	display: flex;
	position: relative;
	justify-content: space-between;
	background-color: var(--background);
	border-bottom: 1px solid var(--surface);
	@include bp(mobile) {
		display: block;
		position: fixed;
		width: 100%;
		background-color: rgba(var(--background-opacity), 0.98);
		z-index: 3;
		top: 0;
		min-height: 71px;
	}

	.logo {
		transform: translateY(-3px);
		@include bp(mobile) {
			transform: none;
			position: absolute;
			left: 0;
			top: 0;
			height: 71px;
			width: 58px;
		}

		a {
			display: block;
			width: 125px;
			height: 60px;
			text-indent: -9999px;
			background-size: contain;
			background-repeat: no-repeat;
			background-image: url('/images/logo_lg.svg');
			@media (prefers-color-scheme: dark) {
				background-image: url('/images/logo_lg_white.svg');
			}

			@include bp(mobile) {
				width: 100%;
				height: 100%;
				background-image: url('/images/logo_sm.svg');
				background-size: 22px;
				background-position: 50% 45%;
				@media (prefers-color-scheme: dark) {
					background-image: url('/images/logo_sm_white.svg');
				}
			}
		}
	}

	ul {
		display: flex;
		list-style: none;
		margin: 0;
		@include bp(mobile) {
			display: none;
			margin-top: 51px;
		}

		&.active {
			@include bp(mobile) {
				display: block;
			}
		}

		li {
			margin-right: 20px;
			line-height: 60px;
			@include bp(mobile) {
				line-height: 30px;
			}
			@include bp(mobile) {
				margin-right: 10px;
				font-size: 0.8rem;
			}

			&:last-child {
				margin-right: 0;
			}

			a {
				color: var(--on-surface);

				&:hover {
					color: var(--on-surface-variant);
				}
			}
		}
	}

	.menu {
		display: none;
		-webkit-mask-image: url('/images/menu.svg');
		mask-image: url('/images/menu.svg');
		-webkit-mask-repeat: no-repeat;
		mask-repeat: no-repeat;
		-webkit-mask-position: 50%;
		mask-position: 50%;
		-webkit-mask-size: 20px;
		mask-size: 20px;
		background: var(--on-surface);
		position: absolute;
		top: 0;
		right: 0;
		height: 71px;
		width: 58px;
		transition: ease all 0.2s;

		&.active {
			-webkit-mask-image: url('/images/menu-close.svg');
			mask-image: url('/images/menu-close.svg');
			-webkit-mask-size: 16px;
			mask-size: 16px;
		}

		@include bp(mobile) {
			display: block;
		}
	}
}