section {
	&.devotion {
		padding: 0;
		
		.devotion-parent {
			position: relative;

			> * {
				position: relative;
			}
		}

		.banner {
			max-width: 800px;
			margin: 0 auto 1.5rem;

			@include bp(sm) {
				margin-bottom: 1.3rem;
			}

			&.video {
				> div {
					padding-top: 56.25%;
					height: 0;
					position: relative;
					background-color: #000;

					iframe {
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
					}
				}
			}

			&.banner--full {
				max-width: 100%;
				margin-bottom: 0;

				.banner-image {
					height: 600px;
					background-size: cover;
					background-repeat: no-repeat;

					@include bp(lrg) {
						background-position: center;
						background-size: contain;
						background-repeat: repeat-x;
					}
					@include bp(sm) {
						height: 300px;
					}
					@include bp(mini) {
						height: 250px;
					}
				}
			}

			&.banner--logo {
				padding-top: 2.25rem;
				margin-bottom: 0;

				.banner-image {
					max-height: none;
					height: auto;
					margin: 0;
					padding: 0 2.5rem;

					img {
						display: block;
						margin: 0 auto;
					}
				}
			}

			.banner-image {
				height: 400px;
				max-height: 40vh;
				overflow: hidden;
				background-size: contain;
				background-position: center center;
				opacity: 0;
				transition: ease 0.5s height;

				@include bp(sm) {
					height: 300px;
				}
				@include bp(mini) {
					height: 250px;
				}
			}
		}

		.devotion-header {
			padding: 35px 40px;
			max-width: 880px;
			margin: 0 auto;
			text-align: center;

			@include bp(mini) {
				padding: 30px 20px;
			}

			h1 {
				color: var(--on-background);
				margin: 0;
			}

			h2 {
				color: var(--primary);
			}
		}
		
		.devotion-body {
			padding: 0 40px 40px;
			max-width: 880px;
			margin: 0 auto;
			z-index: 2;
	
			@include bp(mini) {
				padding: 0 20px 110px;
			}

			&.info-page {
				@include bp(mini) {
					padding: 0 20px 50px;
				}
			}

			blockquote {
				font-family: $verse;
				font-style: italic;
				font-size: 1.08rem;
				color: var(--accent-variant);
				margin: 1.5rem 1.5rem 1.6rem;

				@include bp(sm) {
					margin: 1.3rem 0 1.4rem;
				}

				&:after {
					content: '';
					border-bottom: 2px solid var(--primary);
					display: block;
					width: 80px;
					margin: 1.5rem auto 0;
					
					@include bp(sm) {
						margin-top: 1.3rem;
					}
				}
			}

			ul {
				&:not(.credits) {
					list-style: disc;
					padding-left: 1rem;
				}
			}

			h3, h4 {
				color: var(--on-background);
				margin-bottom: 0;
			}
		}

		.devotion-links {
			display: flex;
			justify-content: space-between;
			z-index: 2;
			margin: 0 auto;
			max-width: 880px;
			padding: 0 40px 40px;

			@include bp(mini) {
				position: fixed;
				width: 100%;
				height: 85px;
				padding: 20px 20px 30px;
				left: 0;
				bottom: 0;
				background-color: var(--background);
				border-top: 1px solid var(--surface);
			}

			a, span {
				font-family: $header;
				font-size: 0.8rem;
				font-weight: bold;
				text-align: center;

				&.disabled {
					visibility: hidden;
				}

				&.next, &.prev {
					flex-basis: 20%;
					@include bp(mini) {
						text-indent: -9999px;
						-webkit-mask-size: contain;
						mask-size: contain;
						-webkit-mask-repeat: no-repeat;
						mask-repeat: no-repeat;
						-webkit-mask-image: url('/images/caret.svg');
						mask-image: url('/images/caret.svg');
						-webkit-mask-position: 0 50%;
						mask-position: 0 50%;
						height: 17px;
						width: 16px;
						background: var(--on-surface);

						&:hover {
							background: var(--on-surface-variant);
						}
					}
				}

				&.view-all {
					flex-basis: 60%;
				}

				&.prev {
					text-align: left;
					@include bp(mini) {
						text-align: initial;
					}
				}

				&.next {
					text-align: right;
					@include bp(mini) {
						transform: scaleX(-1);
						text-align: initial;
					}
				}
			}
		}
	}
}